<template>
  <node :show="false"/>
</template>

<script>
import Node from './Node.vue'

export default {
  name: "EmptyNode",
  components: {Node},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
